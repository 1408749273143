import { Component, OnInit } from "@angular/core";
import moment from "moment";

import { Router } from "@angular/router";
import { AuthService } from "app/services/auth/auth.service";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { ImagesService } from "app/services/helper/images.service";
import { PersonService } from "app/services/person/person.service";
import { ProjectService } from "app/services/project/project.service";
import { UserService } from "app/services/user/user.service";
import { VacacionesService } from "app/services/vacaciones/vacaciones.service";

@Component({
	selector: "app-tandem-calendar",
	templateUrl: "./tandem-calendar.component.html",
	styleUrls: ["./tandem-calendar.component.scss"],
})
export class TandemCalendarComponent implements OnInit {
	dias = [];
	persons = [];
	user: number;
	date = new Date();
	dayI = new Date(this.date.getFullYear(), this.date.getMonth(), 1);
	daysL = Number(moment(new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0)).format("DD"));

	mes = this.date.getMonth();
	agno = this.date.getFullYear();
	aux = " ";
	meses = {
		0: "Enero",
		1: "Febrero",
		2: "Marzo",
		3: "Abril",
		4: "Mayo",
		5: "Junio",
		6: "Julio",
		7: "Agosto",
		8: "Septiembre",
		9: "Octubre",
		10: "Noviembre",
		11: "Diciembre",
	};
	solicitudes = [];
	constructor(
		private vacationSvc: VacacionesService,
		private authSvc: AuthService,
		private personSvc: PersonService,
		private userSvc: UserService,
		private imagesSvc: ImagesService,
		private projectSvc: ProjectService,
		private evaluationSvc: EvaluationService,
		private router: Router,
	) {}

	ngOnInit(): void {
		this.userSvc.getUser().subscribe((data) => {
			this.user = data.id;
			this.evaluationSvc.getCalendarioTandem(data.id, this.mes + 1, this.agno).subscribe((data) => {
				this.dias = data.dias;
				this.persons = data.lista;
				//console.log(data)
			});
		});
	}

	changeMes(it) {
		if (it == -1) {
			if (this.mes != 0) {
				this.mes -= 1;
			} else {
				this.mes = 11;
				this.agno -= 1;
			}
		}
		if (it == 1) {
			if (this.mes != 11) {
				this.mes += 1;
			} else {
				this.mes = 0;
				this.agno += 1;
			}
		}

		this.evaluationSvc.getCalendarioTandem(this.user, this.mes + 1, this.agno).subscribe((data) => {
			this.dias = data.dias;
			this.persons = data.lista;
		});
	}

	getImageUser(nickname) {
		return this.imagesSvc.getImageThumb(nickname);
	}

	getDiaSemana(d) {
		switch (moment(this.agno + "-" + (this.mes + 1) + "-" + d).isoWeekday()) {
			case 1:
				return "L";
			case 2:
				return "M";
			case 3:
				return "X";
			case 4:
				return "J";
			case 5:
				return "V";
			case 6:
				return "S";
			case 7:
				return "D";
			default:
				return "-";
		}
	}

	goToEvaluation(id) {
		if (id) {
			this.evaluationSvc.getLinkEvaluation(id).subscribe((data) => {
				this.router.navigate(["/vuelta-d/form"], {
					queryParams: {
						formId: data.formId,
						personId: data.personId,
						periodId: data.periodId,
						iterationNumber: data.iterationNumber,
					},
				});
			});
		}
	}
}
