<ng-container>
	<h2 mat-dialog-title class="m-0 mb-8">
		Resumen horario {{ day }}. {{ solicitud.estado == 0 ? "Solicitud pendiente " : "" }}
		{{ solicitud.estado == 1 ? "Registro anterior modificado " : "" }}
	</h2>

	@if (solicitud.estado == 0) {
		<div class="w-100-p" fxLayout="row" fxLayoutAlign="center center" style="padding-bottom: 4px">
			<h2 style="color: orange">Solicitud pendiente</h2>
		</div>
	}
	@if (solicitud.estado == 1) {
		<!-- <div class="w-100-p" fxLayout="row" fxLayoutAlign="center center" style="padding-bottom: 4px">
			<h2 style="color: green">Solicitud aceptada</h2>
		</div> -->
	}

	@if (solicitud.estado == 2) {
		<div class="w-100-p" fxLayout="row" fxLayoutAlign="center center" style="padding-bottom: 4px">
			<h2 style="color: brown">Solicitud rechazada</h2>
		</div>
	}

	<mat-dialog-content class="mat-typography" style="width: 60vh">
		<mat-vertical-stepper
			orientation="vertical"
			disableRipple
			linear="false"
			[selectedIndex]="-1"
			[linear]="false"
			style="padding: 0px !important; pointer-events: none"
		>
			@if (registro.length > 0) {
				<mat-step *ngFor="let entrada of registro" [completed]="false">
					<ng-template matStepLabel>
						<p class="m-0">
							<span style="font-size: 19px">{{ entrada.accion }}</span>
						</p>
						<p class="m-0">
							<b style="font-size: 17px">
								{{ getHoraLiteral(entrada.hora) }}
							</b>
						</p>
					</ng-template>
				</mat-step>
			} @else {
				<mat-step>
					<ng-template matStepLabel>
						<p class="m-0">
							<span style="font-style: italic; font-size: 19px">No hay registros para este día</span>
						</p>
					</ng-template>
				</mat-step>
			}
		</mat-vertical-stepper>

		<div class="w-100-p" fxLayout="row" fxLayoutAlign="center center" style="padding: 8px">
			<div class="w-100-p" fxLayout="column" fxLayoutAlign="center center">
				<div class="w-100-p" fxLayout="row" fxLayoutAlign="center center">
					<span><b>Tiempo total </b></span>
				</div>
				<div class="w-100-p" fxLayout="row" fxLayoutAlign="center center">{{ converMilisegundos(totalTime) }}</div>
			</div>
			<div class="w-100-p" fxLayout="column" fxLayoutAlign="center center">
				<div class="w-100-p" fxLayout="row" fxLayoutAlign="center center">
					<span><b>Tiempo trabajado</b></span>
				</div>
				<div class="w-100-p" fxLayout="row" fxLayoutAlign="center center">{{ converMilisegundos(totalWorkTime) }}</div>
			</div>

			<div class="w-100-p" fxLayout="column" fxLayoutAlign="center center">
				<div class="w-100-p" fxLayout="row" fxLayoutAlign="center center">
					<span><b>Tiempo de descanso</b></span>
				</div>
				<div class="w-100-p" fxLayout="row" fxLayoutAlign="center center">{{ converMilisegundos(totalPauseTime) }}</div>
			</div>
		</div>

		<div class="w-100-p" fxLayout="row" fxLayoutAlign="start start" style="padding-bottom: 8px">
			<span><b>Motivo</b></span>
		</div>
		<div
			class="w-100-p"
			fxLayout="row"
			fxLayoutAlign="start start"
			style="background-color: rgb(217, 217, 217); border: 1px solid gray; padding: 8px; border-radius: 5px"
		>
			<span style="font-style: italic">{{ solicitud?.motivo ?? "No hay motivo registrado" }}</span>
		</div>

		@if (solicitud.estado == 2 || solicitud.estado == 1) {
			<div class="w-100-p" fxLayout="row" fxLayoutAlign="start start" style="padding-bottom: 4px; padding-top: 4px">
				<span><b>Revisión del responsable</b></span>
			</div>
			<div
				class="w-100-p"
				fxLayout="row"
				fxLayoutAlign="start start"
				style="background-color: rgb(217, 217, 217); border: 1px solid gray; padding: 8px; border-radius: 5px"
			>
				<span style="font-style: italic">{{ solicitud?.observacionesManager ?? "No hay motivo registrado" }}</span>
			</div>
		}

		@if (solicitud.estado == 0 && isManager) {
			<div class="w-100-p" fxLayout="row" fxLayoutAlign="start start" style="padding-bottom: 4px; padding-top: 4px">
				<span><b>Revisión del responsable</b></span>
			</div>

			@if (projectConfig.canReSolicitarCh == 1) {
				<div fxLayout="row" fxLayoutAlign="start start">
					<mat-checkbox [(ngModel)]="cannotResend"> Vetar la repetición de esta solicitud </mat-checkbox>
				</div>
			}
			<div fxLayout="row" fxLayoutAlign="center center">
				<textarea
					class="form-control"
					[(ngModel)]="observacionesManager"
					placeholder="Escribe el motivo de este cambio"
					style="height: 80px; width: 90%; margin-top: 16px"
					[maxLength]="250"
				></textarea>
			</div>
			<div fxLayout="row" fxLayoutAlign="center center">
				<span style="width: 90%; text-align: right; font-size: 14px; margin-top: 3px"> {{ observacionesManager.length }}/10</span>
			</div>

			<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
				<div fxLayout="column" fxLayoutAlign="center center" style="padding-left: 8px; padding-right: 8px">
					<button
						mat-raised-button
						[ngClass]="observacionesManager.length < 10 ? 'btn-disable' : 'botonok'"
						[disabled]="observacionesManager.length < 10"
						(click)="moderarSolicitud(1)"
					>
						Aceptar solicitud
					</button>
				</div>
				<div fxLayout="column" fxLayoutAlign="center center" style="padding-left: 8px; padding-right: 8px">
					<button
						mat-raised-button
						[ngClass]="observacionesManager.length < 10 ? 'btn-disable' : 'botonend'"
						[disabled]="observacionesManager.length < 10"
						(click)="moderarSolicitud(2)"
					>
						Denegar solicitud
					</button>
				</div>
			</div>
		}

		@if (solicitud.estado == 2 && !isManager && solicitud.canResolicitar == 1 && projectConfig.canReSolicitarCh == 1) {
			<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
				<div fxLayout="column" fxLayoutAlign="center center" style="padding-left: 8px; padding-right: 8px">
					<button mat-raised-button color="primary" (click)="solicitarAgain()">Volver a solicitar</button>
				</div>
			</div>
		}
		@if (canRevocar) {
			@if (!dialogoRevocar) {
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<div fxLayout="column" fxLayoutAlign="center center" style="padding-left: 8px; padding-right: 8px">
						<button mat-raised-button color="primary" (click)="mostrarRevocar()">Revocar solicitud</button>
					</div>
				</div>
			} @else {
				<div fxLayout="row" fxLayoutAlign="center center" class="mt-12">
					<span style="text-align: left">¿Desea restaurar el registro horario existente antes de la solicitud de modificación?</span>
				</div>
				<div fxLayout="row" fxLayoutAlign="center center">
					<div fxLayout="column" fxLayoutAlign="center center" style="padding-left: 8px; padding-right: 8px">
						<button mat-raised-button color="primary" (click)="mostrarRevocar()">Cancelar</button>
					</div>

					<div fxLayout="column" fxLayoutAlign="center center" style="padding-left: 8px; padding-right: 8px">
						<button mat-raised-button color="primary" (click)="confirmarRevocar()">Restaurar</button>
					</div>
				</div>
			}
		}
	</mat-dialog-content>
</ng-container>
