import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ControlHorarioService } from "app/services/control-horario/control-horario.service";
import { ProjectService } from "app/services/project/project.service";
import moment from "moment";

@Component({
	selector: "app-dialog-time-resumen",
	templateUrl: "./dialog-time-resumen.component.html",
	styleUrls: ["./dialog-time-resumen.component.scss"],
})
export class DialogTimeResumenComponent implements OnInit {
	entradas = [];
	editMode = false;
	personId;
	day;
	registro = [];
	solicitud;
	type;
	isManager = false;

	observacionesManager = "";
	cannotResend = false;

	totalWorkTime = 0;
	totalPauseTime = 0;
	totalTime = 0;

	canRevocar = false;
	dialogoRevocar = false;

	//Variables moderación de managers

	projectConfig;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private chSvc: ControlHorarioService,
		private dialogRef: MatDialogRef<DialogTimeResumenComponent>,
		private projectSvc: ProjectService,
	) {
		this.projectConfig = this.projectSvc.projectConfig;
	}

	ngOnInit(): void {
		this.isManager = Number(this.data.manager) == 1;

		this.editMode = this.data.editMode;
		this.solicitud = this.data.solicitud;
		this.day = moment(this.solicitud.dateReference).add(2, "hours").format("YYYY-MM-DD");
		this.type = this.data.type;

		this.entradas = this.data.registros;
		this.canRevocar = this.data.canRevocar;

		let index = 0;
		for (let entrada of this.entradas) {
			if (index == 0) {
				this.registro.push({ accion: `Ha picado la entrada`, hora: entrada.entry_date_time });
				if (entrada.break_type == 1) {
					this.registro.push({ accion: `Ha realizado a una pausa`, hora: entrada.exit_date_time });
				} else if (entrada.break_type == 2) {
					this.registro.push({ accion: `Ha finalizado jornada`, hora: entrada.exit_date_time });
				} else if (entrada.break_type == 4) {
					this.registro.push({ accion: `Ha parado para comer`, hora: entrada.exit_date_time });
				} else {
					this.registro.push({ accion: `Ha realizado a una pausa`, hora: entrada.exit_date_time });
				}
			} else {
				this.registro.push({ accion: `Ha reanudado la jornada`, hora: entrada.entry_date_time });
				if (entrada.break_type == 1) {
					this.registro.push({ accion: `Ha realizado a una pausa`, hora: entrada.exit_date_time });
				} else if (entrada.break_type == 2) {
					this.registro.push({ accion: `Ha finalizado jornada`, hora: entrada.exit_date_time });
				} else if (entrada.break_type == 4) {
					this.registro.push({ accion: `Ha parado para comer`, hora: entrada.exit_date_time });
				} else {
					this.registro.push({ accion: `Ha realizado a una pausa`, hora: entrada.exit_date_time });
				}
			}

			if (entrada.work_time) {
				this.totalWorkTime += Number(entrada.work_time);
				this.totalTime += Number(entrada.work_time);
			}
			if (entrada.break_time) {
				this.totalPauseTime += Number(entrada.break_time);
				this.totalTime += Number(entrada.break_time);
			}

			index++;
		}
	}

	moderarSolicitud(aceptado) {
		if (aceptado == 1) {
			this.chSvc.moderarSolicitud(this.solicitud.id, 1, this.observacionesManager, this.cannotResend).subscribe((data) => {
				this.dialogRef.close(data);
			});
		} else {
			this.chSvc.moderarSolicitud(this.solicitud.id, 2, this.observacionesManager, this.cannotResend).subscribe((data) => {
				this.dialogRef.close(data);
			});
		}
	}

	solicitarAgain() {
		this.dialogRef.close({ resolicitar: true });
	}

	getHoraLiteral(hora) {
		return hora ? moment(hora).format("HH:mm:ss") : "";
	}

	converMilisegundos(horas) {
		var x = parseInt(horas);
		var tempTime = moment.duration(x);

		var res = "";

		if (tempTime.hours() > 0) {
			res += tempTime.hours() + " h ";
		}
		if (tempTime.minutes() > 0) {
			res += tempTime.minutes() + " min ";
		}
		if (tempTime.seconds() > 0) {
			res += tempTime.seconds() + " seg ";
		}

		if (res == "") {
			res = "-";
		}

		return res;
	}

	mostrarRevocar() {
		this.dialogoRevocar = !this.dialogoRevocar;
	}

	confirmarRevocar() {
		//Revocamos solicitud
		this.chSvc.revocarSolicitudRRHH(this.solicitud.id).subscribe((data) => {
			this.dialogRef.close(data);
		});
	}
}
