import { Component, Input, OnInit } from "@angular/core";
import moment from "moment";

import { MatSnackBar } from "@angular/material/snack-bar";

import { BadgesService } from "../../services/badges/badges.service";
import { ImagesService } from "../../services/helper/images.service";
import { PostsService } from "../../services/posts/posts.service";
import { ProjectService } from "../../services/project/project.service";

import { Comment } from "../../shared/models/comun.interface";
import { User } from "../../shared/models/user.interface";

@Component({
	selector: "fb-comentar",
	templateUrl: "./comentar.component.html",
	styleUrls: ["./comentar.component.scss"],
})
export class ComentarComponent implements OnInit {
	@Input("id_badge") badgeId: number;
	@Input("id_post") postId: number;
	@Input("user") user: User;
	@Input("canEdit") canEdit: any;

	// form: FormGroup;
	projectId: number;
	comentario: string;
	comments: Array<Comment>;
	canDelete = 0;
	canEditComent;
	projectConfig;
	constructor(
		// private _formBuilder: FormBuilder,
		private snackBar: MatSnackBar,
		private projectSvc: ProjectService,
		private imagesSvc: ImagesService,
		private badgeSvc: BadgesService,
		private postSvc: PostsService,
	) {
		this.comments = [];
		this.projectConfig = this.projectSvc.projectConfig;
	}

	ngOnInit(): void {
		// this.form = this._formBuilder.group({
		// 	comentario: ['']
		// });
		this.canEditComent = this.canEdit;
		this.comentario = "";

		this.projectId = parseInt(this.projectSvc.getProjectId());
		moment.locale(this.user.language);

		if (this.projectConfig.tandemMode) {
			if (this.user) {
				if (this.user.puestos) {
					if (this.user.puestos.length > 0) {
						if (this.user.puestos[0].puesto.includes("ETI") || this.user.puestos[0].puesto.includes("FORMA")) {
							this.canDelete = 1;
						}
					}
				}
			}
		}
		this.loadComments();
	}

	momentDate(timestamp: number) {
		const hoy = moment();
		const objDate = moment(timestamp);
		const diffDias = hoy.diff(objDate, "days");

		let momentDate = objDate.fromNow();
		if (diffDias > 21) {
			momentDate = objDate.format("DD MMMM YYYY");
		}

		return momentDate;
	}

	getImagePerson(nickname: string) {
		return this.imagesSvc.getImageThumb(nickname);
	}

	loadComments(): void {
		if (this.badgeId) {
			this.badgeSvc.badgeComments(this.badgeId).subscribe((data: any) => {
				// console.log("coments badge", data);
				this.comments = data.elements;
			});
		} else if (this.postId) {
			this.postSvc.postComments(this.postId).subscribe((data: any) => {
				// console.log("coments post", data);
				this.comments = data.elements;
			});
		}
	}

	emojiSelected(event) {
		const emoji = event.emoji && event.emoji.native ? event.emoji.native : null;
		if (emoji) {
			this.comentario = this.comentario + event.emoji.native;
		}
	}

	saveComment() {
		if (this.comentario === "") {
			this.snackBar.open("El comentario no puedes estar vacio", "X", {
				duration: 3000,
			});
		} else {
			if (this.badgeId) {
				this.badgeSvc.createComment(this.badgeId, this.comentario).subscribe(
					(data) => {
						this.comentario = "";
						this.loadComments();
					},
					(err) => {
						this.snackBar.open(err, "X", {
							duration: 3000,
						});
					},
				);
			} else if (this.postId) {
				this.postSvc.createComment(this.postId, this.comentario).subscribe(
					(data) => {
						this.comentario = "";
						this.loadComments();
					},
					(err) => {
						this.snackBar.open(err, "X", {
							duration: 3000,
						});
					},
				);
			}
		}
	}
}
