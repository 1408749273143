import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { environment } from "../../../environments/environment";
import { AuthService } from "../auth/auth.service";
import { AlertError } from "../error-handler";
import { HelpersService } from "../helper/helpers.service";
import { ProjectService } from "../project/project.service";

@Injectable({
	providedIn: "root",
})
export class ControlHorarioService {
	onReloadTimeControl: Subject<any>;
	onDestroyTimeControl: Subject<any>;

	constructor(
		private http: HttpClient,
		private authSvc: AuthService,
		private projectSvc: ProjectService,
		private helpersSvc: HelpersService,
	) {
		this.onReloadTimeControl = new Subject();
		this.onDestroyTimeControl = new Subject();
	}

	getTiposEntradas() {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/userTimeAuth/entryTypes";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	getUserState() {
		const accessToken = this.authSvc.getAccessToken();

		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
		};

		const url = environment.urlDomain + "/api/v1/userTimeAuth/userState";

		return new Observable<any>((observer) => {
			this.http.get(url, options).subscribe(
				(data: any) => {
					const datos = this.procesarUserState(data.regList);
					const result = {
						breakType: data.breakType,
						entryType: data.entryType,
						workTimeTotal: datos.tiempoTotal,
						firstEntry: datos.firstEntry,
						lastPause: datos.lastPause,
						lastEntry: datos.lastEntry,
						regEdit: datos.regEdit,
					};
					console.log("userState", data);
					observer.next(result);
				},
				(err) => {
					AlertError.showError(err);
				},
			);

			return { unsubscribe() {} };
		});
	}

	private procesarUserState(data) {
		// BreakType (0=>Entrada, 1=> Pausa, 2=>Salida, 3=>Autocierre, 4=>Comida)
		// {id: 1, entryDateTime: '8:00', exitDateTime: '8:22', breakType: '1', workTime: '1320000', breakTime: '1380000'},
		let tiempoTotal = 0;
		let firstEntry = null;
		let lastPause = null;
		let lastEntry = null;
		let regEdit = null;

		if (data.length > 0) {
			for (let i in data) {
				let index = parseInt(i);
				const reg = data[i];
				const regAnt = data[(index - 1).toString()];
				const regSig = data[(index + 1).toString()];

				if (index === 0) {
					firstEntry = reg.entryDateTime;
				}

				// ENTRADA
				if ((reg.breakType === 0 || reg.exitDateTime !== null) && (regAnt === undefined || regAnt.breakType !== 1)) {
					lastEntry = reg.entryDateTime;
					regEdit = {
						id: reg.id,
						evento: "Entrada",
						fecha: reg.entryDateTime,
						exit: 0,
					};
				}

				// SALIDA
				if ((reg.breakType === 2 || reg.breakType === 3) && reg.exitDateTime !== null) {
					tiempoTotal += reg.workTime;
					regEdit = {
						id: reg.id,
						evento: "Salida",
						fecha: reg.exitDateTime,
						exit: 1,
					};
				}

				if ((reg.breakType === 1 || reg.breakType === 4) && reg.exitDateTime !== null) {
					tiempoTotal += reg.workTime;

					// INICIO PAUSA
					lastPause = reg.exitDateTime;
					regEdit = {
						id: reg.id,
						evento: "Inicio pausa",
						fecha: reg.exitDateTime,
						exit: 1,
					};

					// FIN PAUSA
					if (regSig !== undefined) {
						lastEntry = regSig.entryDateTime;
						regEdit = {
							id: regSig.id,
							evento: "Fin pausa",
							fecha: regSig.entryDateTime,
							exit: 0,
						};
					}
				}
			}
		}

		let datos = {
			tiempoTotal: tiempoTotal,
			firstEntry: firstEntry,
			lastPause: lastPause,
			lastEntry: lastEntry,
			regEdit: regEdit,
		};

		return datos;
	}

	startTelework(tipoEntrada: number, positionUser: any) {
		const accessToken = this.authSvc.getAccessToken();

		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
		};

		const url = environment.urlDomain + "/api/v1/userTimeAuth/startRemoteWork";
		const data = {
			tipoEntrada: tipoEntrada,
			latitude: positionUser.latitude,
			longitude: positionUser.longitude,
			accuracy: positionUser.accuracy,
			errorGps: positionUser.errorGps,
		};

		return new Observable<any>((observer) => {
			this.http.post(url, data, options).subscribe(
				(data) => {
					observer.next(data);
				},
				(err) => {
					AlertError.showError(err);
				},
			);

			return { unsubscribe() {} };
		});
	}

	stopTelework(breakType: number, positionUser: any) {
		const accessToken = this.authSvc.getAccessToken();

		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
		};

		const url = environment.urlDomain + "/api/v1/userTimeAuth/stopRemoteWork";
		const data = {
			breakType: breakType,
			latitude: positionUser.latitude,
			longitude: positionUser.longitude,
			accuracy: positionUser.accuracy,
			errorGps: positionUser.errorGps,
		};

		return new Observable<any>((observer) => {
			this.http.post(url, data, options).subscribe(
				(data) => {
					observer.next(data);
				},
				(err) => {
					AlertError.showError(err);
				},
			);

			return { unsubscribe() {} };
		});
	}

	updateTelework(dataRegistro) {
		const accessToken = this.authSvc.getAccessToken();

		const options = {
			headers: new HttpHeaders({
				Authentication: "Bearer " + accessToken,
				"Content-Type": "application/json; charset=utf-8",
			}),
		};

		const url = environment.urlDomain + "/api/v1/userTimeAuth/updateRemoteWork";

		return new Observable<any>((observer) => {
			this.http.post(url, dataRegistro, options).subscribe(
				(data) => {
					observer.next(data);
				},
				(err) => {
					AlertError.showError(err);
				},
			);

			return { unsubscribe() {} };
		});
	}

	userTimeTemplate() {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/user/template";
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	userTasks() {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/user/tasks";
			this.http.get(url, options).subscribe((response: any) => {
				observer.next(response);
			}),
				(err) => {
					AlertError.showError(err);
				};
		});
	}

	timeTemplate(personId: number) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/time-template/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	resgistrosHorarios(page: number, personId: any = "", bossId?: any, name_filter?: any, fecha_ini?: any, fecha_fin?: any, estado?: number) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const params = {
				personId: personId,
				bossId: bossId,
				page: page,
				pageSize: 20,
				//Filtering params
				name_filter: name_filter,
				fecha_ini: fecha_ini,
				fecha_fin: fecha_fin,
				estado,
				//pageSize: environment.pageSize
			};

			var url = environment.urlDomain + "/api/v1/userTimeAuth/resumen?" + this.helpersSvc.serializeData(params);

			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					AlertError.showError(err);
				},
			);
		});
	}

	checkJornadaCompletada(personId: number) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/userTimeAuth/checkJornadaCumplida/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error categories", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				},
			);
		});
	}

	checkPicadoAutomatico(personId: number) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/userTimeAuth/autoentry/" + personId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error categories", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				},
			);
		});
	}

	updateValidation(idRegistro: number, action: number, motivo: string) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const body = { motivo: motivo };
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/userTimeAuth/updateValidation/" + idRegistro + "/" + action;
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error categories", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				},
			);
		});
	}

	justificar(personId: number, fecha: string, motivo: string) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const body = { personId, fecha, motivo };
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/userTimeAuth/justificar";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error categories", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				},
			);
		});
	}

	postModificacion(fecha: string, motivo: string, startDate: string, endDate: string, entrada: number, pausas?) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const body = {
				fecha,
				motivo,
				startDate,
				endDate,
				pausas,
				entrada,
			};
			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/userTimeAuth/postModificacion";
			this.http.post(url, body, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error categories", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				},
			);
		});
	}

	getPreliminarBySolicitud(solicitudId: number) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/userTimeAuth/getPreliminarBySolicitud/" + solicitudId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error categories", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				},
			);
		});
	}

	getBackupBySolicitud(solicitudId: number) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/userTimeAuth/getBackupBySolicitud/" + solicitudId;
			this.http.get(url, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error categories", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				},
			);
		});
	}

	moderarSolicitud(solicitudId: number, estado: number, observacionesManager: string, cannotResend: boolean) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/userTimeAuth/moderarSolicitud";
			this.http.put(url, { solicitudId, estado, observacionesManager, cannotResend }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error categories", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				},
			);
		});
	}

	revocarSolicitudRRHH(solicitudId: number) {
		return new Observable<any>((observer) => {
			const accessToken = this.authSvc.getAccessToken();

			const options = {
				headers: new HttpHeaders({
					Authentication: "Bearer " + accessToken,
					"Content-Type": "application/json; charset=utf-8",
				}),
			};

			const url = environment.urlDomain + "/api/v1/userTimeAuth/revocarSolicitudRRHH";
			this.http.put(url, { solicitudId }, options).subscribe(
				(response: any) => {
					observer.next(response);
				},
				(err) => {
					console.log("error categories", err);
					let msgError = "Se ha producido un error cargando los datos";

					observer.error(msgError);
				},
			);
		});
	}
}
